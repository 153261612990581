import { Component, OnInit, Input } from '@angular/core';
import { ModalController, Config } from "@ionic/angular";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-detalleremision',
  templateUrl: './detalleremision.page.html',
  styleUrls: ['./detalleremision.page.scss'],
})
export class DetalleremisionPage implements OnInit {

  @Input() info;
  ios: boolean;
  public vehiculo = null;
  public remision = null;
  public urlfinal = null;

  constructor(
    public modalCtrl: ModalController,
    public config: Config,
    public sanitizer: DomSanitizer,
  ) { }

  

  ngOnInit() {
    this.ios = this.config.get('mode') === `ios`;
    // this.bono.bonos.reverse();
    this.remision = this.info.remision;
    this.vehiculo = this.info.vehiculo;
    // console.log(this.bonos)
    console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
    console.log(this.info)
    console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
    if(this.remision){
      this.picImage = "data:image/jpg;base64," + (this.remision.FOTOGRAFIA ? this.remision.FOTOGRAFIA.FOTOGRAFIA[0] : null) ;
      this.urlfinal =  this.sanitizer.bypassSecurityTrustUrl(this.picImage)
    }
  }

  public close() {
    let response = {
      param: 0
    }
    this.modalCtrl.dismiss(response);
  }

  public picImage = "";
}
