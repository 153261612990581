import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AppService } from './app.service'

export interface Empresa {
  color: string,
  idempresa: number,
  imagen: string,
  telefono: string,
  currency: string,
  edit:any,
  callbacklink:string,
  banner: string,
  paiscode: string,
  pickupsucursalenabled: string,
  sucursales:any,
  carritoonline:any
};

export interface Caso {
  empresa: Empresa,
  error: number,
  idcasosocialmanager: string
}

export interface Direc {
  direc: string,
  lat: number,
  lng: number
}

export interface metodopago {
  idempresa: number,
  idmetodo_pago: number,
  nombre: string
};

export interface infoCompra {
  paymentmethod:metodopago,
  dirgmap:Direc,
  telefono:string,
  shipping:number,
  refdir:string,
  nit:string,
  name:string,
  correo:string,
  departamento:any,
  municipio:any,
  sucursal:any,
  metodoentrega:any
}

@Injectable({
  providedIn: 'root'
})
export class LocalsaveService {

  private direc:BehaviorSubject<Direc> = new BehaviorSubject<Direc>(null);
  private infocompra:BehaviorSubject<infoCompra> = new BehaviorSubject<infoCompra>(null);
  private tknt:BehaviorSubject<String> = new BehaviorSubject<String>('');
  public empresa:BehaviorSubject<Empresa> = new BehaviorSubject<Empresa>(null);
  public caso:BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public subcategorias:BehaviorSubject<[]> = new BehaviorSubject<[]>([]);
  private saldodisponible:BehaviorSubject<number> = new BehaviorSubject<number>(0); 
  public tipocliente:any;

  constructor(
    private appservice: AppService,
  ) { }

  loadcaso(tkn){
    this.appservice.getEmpresa({ token: tkn }).subscribe(
      (res: any) => {
        if (res.error == 0) {
          // console.log(res);
          this.appservice.Data.tipocliente=res.tipocliente;
          this.setempresa(res.empresa);
          this.setidcaso(res.idcasosocialmanager);
          this.setSaldodisponible(res.saldomovi);
        }else{
          // alert(res.error)
          let errpresa:Empresa={
            idempresa:null,
            color:'error',
            imagen:'error',
            telefono:'error',
            currency:'error',
            edit:'error',
            banner:'error',
            callbacklink:'error',
            paiscode:'error',
            pickupsucursalenabled:'error',
            sucursales:[],
            carritoonline:[]
          }
          this.setempresa(errpresa);
        }
      }
    )
  }

  getSaldodisponible(){
    return this.saldodisponible;
  }

  setSaldodisponible(saldo:number){
    this.saldodisponible.next(saldo);
  }

  getDirec(){
    return this.direc;
  }

  setDirec(dire:Direc){
    this.direc.next(dire);
  }

  getInfocompra(){
    return this.infocompra;
  }

  setInfocompra(info:infoCompra){
    this.infocompra.next(info);
  }

  getempresa() {
    return this.empresa;
  } 

  setempresa(empresa:Empresa){
    // this.put('empresa',empresa);
    this.empresa.next(empresa);
  }

  getsubcat() {
    return this.subcategorias;
  } 

  setsubcat(subcats){
    this.subcategorias.next(subcats);
  }

  gettoken() {
    return this.tknt;
  }

  settoken(token){
    // this.put('token',token);
    this.tknt.next(token);
  }

  getidcaso() {
    return this.caso;
  }

  setidcaso(idcaso){
    // this.put('token',token);
    this.caso.next(idcaso);
  }

  gettipocliente() {
    return this.tipocliente;
  }

  settipocliente(tipo){
    this.tipocliente=tipo;
  }

  //--------------------------------------------------------//

  put(llave, valor) {
    if (typeof (Storage) != "undefined") {
      localStorage.setItem(llave, JSON.stringify(valor));
    } else {
      alert('NO HAY SOPORTE DE DB');
    }
  }

  validate(llave) {
    if (typeof (Storage) != "undefined") {
      if (localStorage.getItem(llave) != null) {
        return true;
      } else {
        return false;
      };
    } else {
      alert('NO HAY SOPORTE DE DB');
    }
  }

  get(llave) {
    if (typeof (Storage) != "undefined") {
      if (localStorage.getItem(llave) != null) {
        let retrievedObject = localStorage.getItem(llave);
        return JSON.parse(retrievedObject);
      } else {
        return {};
      };
    } else {
      alert('NO HAY SOPORTE DE DB');
    }
  }

  delete(llave) {
    localStorage.removeItem(llave);
  }

}
