import { ColorVariable } from './color-variables';
import { Color, RGB } from './color';

export function generateColor(name: string, property: string, value: string): ColorVariable {
  const color = new Color(value);
  const contrast = color.contrast();
  const tint = color.tint();
  const shade = color.shade();

  return {
    name,
    property,
    value,
    valueRgb: rgbToString(color.rgb),
    contrast: contrast.hex,
    contrastRgb: rgbToString(contrast.rgb),
    tint: tint.hex,
    shade: shade.hex,
  };
}

function rgbToString(c: RGB): string {
  return `${c.r},${c.g},${c.b}`;
}