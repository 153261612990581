import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home/consultavehiculo',
    pathMatch: 'full'
  },
  
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'tokeninvalido',
    loadChildren: () => import('./tokeninvalido/tokeninvalido.module').then( m => m.TokeninvalidoPageModule)
  },
  {
    path: 'consultavehiculo',
    loadChildren: () => import('./consultavehiculo/consultavehiculo.module').then( m => m.ConsultavehiculoPageModule)
  },
  {
    path: 'consultaestadocuenta',
    loadChildren: () => import('./consultavehiculo/consultavehiculo.module').then( m => m.ConsultavehiculoPageModule)
  },
  // {
  //   path: 'detalleremision',
  //   loadChildren: () => import('./detalleremision/detalleremision.module').then( m => m.DetalleremisionPageModule)
  // },
  // {
  //   path: 'detalleboleta',
  //   loadChildren: () => import('./detalleboleta/detalleboleta.module').then( m => m.DetalleboletaPageModule)
  // }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
