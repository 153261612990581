import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppService } from './app.service'
import { LocalsaveService } from './localsave.service'
import { ToastController } from '@ionic/angular';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private favorites = [];
  private cart = [];
  private lstprod= [];
  private listadoproductos:BehaviorSubject<[]> = new BehaviorSubject<[]>([]);
  public cartItemCount = new BehaviorSubject(0);
  public isloading:boolean = false;

  constructor(
    private appservice: AppService,
    private localsave: LocalsaveService,
    public toastController: ToastController,
    public loading: LoadingService,
  ) { }

  getCart() {
    return this.cart;
  }

  setCart(carrito){
    this.cart=carrito;
  }

  setCarritoonline(carrito){
    this.cart=carrito;
    this.cartItemCount.next(0)
    for (let prod of carrito){
      this.cartItemCount.next(this.cartItemCount.value + prod.cantidadacomprar)
    }
  }

  getCartItemCount() {
    return this.cartItemCount;
  }

  // getCartItemCount() {
  //   return this.cart.reduce((i,j) => i + j.cantidadacomprar, 0);
  // }
  
  getTotal() {
    return this.cart.reduce((i, j) => i + ((j.oferta != 0 && j.oferta != null) ? j.oferta : j.precio) * j.cantidadacomprar, 0);
  }

  addvarProduct(product) {
    // console.log(product);
    let added = false;
    if (!product.idvariante) {
      for (let p of this.cart) {
        if (p.idproducto === product.idproducto) {
          p.cantidadacomprar += product.cantidadacomprar;
          added = true;
          break;
        }
      }
    } else {
      for (let p of this.cart) {
        if (p.idvariante === product.idvariante) {
          p.cantidadacomprar += product.cantidadacomprar;
          added = true;
          break;
        }
      }
    }
    if (!added) {
      this.cart.push(product);
    }
    // if(this.cartItemCount.getValue() != 0){
      this.cartItemCount.next(this.cartItemCount.value + product.cantidadacomprar);
    // }
    

  }

  // addProduct(product) {
  //   let added = false;
  //   if (!product.idvariante) {
  //     for (let p of this.cart) {
  //       if (p.idproducto === product.idproducto) {
  //         p.cantidadacomprar += product.cantidadacomprar;
  //         added = true;
  //         break;
  //       }
  //     }
  //   } else {
  //     for (let p of this.cart) {
  //       if (p.idvariante === product.idvariante) {
  //         p.cantidadacomprar += 1;
  //         added = true;
  //         break;
  //       }
  //     }
  //   }
  //   if (!added) {
  //     product.cantidadacomprar = 1;
  //     this.cart.push(product);
  //   }
  //   this.cartItemCount.next(this.cartItemCount.value + 1);
  // }

  increaseProduct(product) {
    this.isloading=true;
    this.loading.present();
    let added = false;
    let objprod = {
      idproducto: product.idproducto,
      cantidad: 1,
      idvariante: product.idvariante ? product.idvariante : null,
      idcaso: this.localsave.getidcaso().value,
      pedido: this.cart
    }
    this.appservice.validateCantprod(objprod).subscribe((res: any) => {
      // console.log(res);
      if (res[0].resultado == 1) {

        if (!product.idvariante) {
          for (let p of this.cart) {
            if (p.idproducto === product.idproducto) {
              p.cantidadacomprar += 1;
              product.cantidadacomprar = p.cantidadacomprar;
              added = true;
              break;
            }
          }
        } else {
          for (let p of this.cart) {
            if (p.idvariante === product.idvariante) {
              p.cantidadacomprar += 1;
              product.cantidadacomprar = p.cantidadacomprar;
              added = true;
              break;
            }
          }
        }
        if (!added) {
          product.cantidadacomprar = 1;
          this.cart.push(product);
        }
        this.cartItemCount.next(this.cartItemCount.value + 1);
        objprod.pedido = this.cart;
        this.appservice.updateCarrito(objprod).subscribe((res:any) => {
          this.loading.dismiss();
          this.isloading= false;
        },
        error => {
          this.loading.dismiss();
          this.isloading= false;
        })
      } else {
        this.loading.dismiss();
        this.isloading= false;
        this.presentToast('No hay suficientes unidades')
      }
      
    },
    error => {
      this.loading.dismiss();
      this.isloading= false;
        console.log(error);
      })
  }

  decreaseProduct(product) {
    this.isloading=true;
    this.loading.present();
    let objprod = {
      idproducto: product.idproducto,
      cantidad: -1,
      idvariante: product.idvariante ? product.idvariante : null,
      idcaso: this.localsave.getidcaso().value,
      pedido: this.cart
    }
    this.appservice.validateCantprod(objprod).subscribe(async (res: any) => {
      // console.log(product);
      for await(let [index, p] of this.cart.entries()) {
        // console.log(p);
        if (p.idproducto === product.idproducto && (p.idvariante == null || (p.idvariante == product.idvariante))) {
            p.cantidadacomprar -= 1;
            product.cantidadacomprar = p.cantidadacomprar;
            // product.cantidadacomprar-=1;
            if (p.cantidadacomprar == 0) {
              this.cart.splice(index, 1);
            }           
        }
      }
          if(this.cartItemCount.value > 0){
            this.cartItemCount.next(this.cartItemCount.value - 1);
            objprod.pedido = this.cart;
            this.appservice.updateCarrito(objprod).subscribe((res:any) => {
              this.loading.dismiss();
              this.isloading= false;
            },
            error => {
              this.loading.dismiss();
              this.isloading= false;
            })
            // this.loading.dismiss();
            // this.isloading= false;
          }else {
            this.loading.dismiss();
            this.isloading= false;
          }
    })
  }

  removeProduct(product) {
    let objprod = {
      idproducto: product.idproducto,
      cantidad: -product.cantidadacomprar,
      idvariante: product.idvariante ? product.idvariante : null,
      idcaso: this.localsave.getidcaso().value,
      pedido: this.cart
    }
    this.appservice.validateCantprod(objprod).subscribe(async (res: any) => {
      for await(let [index, p] of this.cart.entries()) {
        if (p.idproducto === product.idproducto && (p.idvariante == null || (p.idvariante == product.idvariante))) {
          this.cartItemCount.next(this.cartItemCount.value - p.cantidadacomprar);
          this.cart.splice(index, 1);
          product.cantidadacomprar = 0;
        }
      }
      objprod.pedido = this.cart;
      this.appservice.updateCarrito(objprod).subscribe((res:any) => {
      })
    })
  }

  getFavorites() {
    return this.favorites;
  }

  addProductoFav(product) {
    let added = false;
    for (let p of this.favorites) {
      if (p.idproducto === product.idproducto) {
        added = true;
        break;
      }
    }
    if (!added) {
      this.favorites.push(product);
    }
  }

  removeProductoFav(product) {
    for (let [index, p] of this.favorites.entries()) {
      if (p.idproducto === product.idproducto) {
        this.favorites.splice(index, 1);
      }
    }
  }

  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      color: 'dark',
      duration: 2000
    });
    toast.present();
  }

  getProductlist() {
    return this.listadoproductos;
  }

  setProductlist(products){
    this.listadoproductos.next(products);
  }

}


