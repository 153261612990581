import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../environments/environment";
import { map } from "rxjs/operators";

export class Data {
  constructor(
    public token: any,
    public saldodisponible = 0,
    public banderarefresh = 0,
    public version: string = 'tigo',
    public alrdyclickdchout: boolean = false,
    public ismissing: boolean = false,
    public tipocliente: any = undefined,
    public categoriaslctd: any = undefined
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class AppService {

  globalip: string = 'https://apismm.isoft-ste.com';
  // globalip: string = 'http://34.82.123.185:5013';
  globalip2: string = 'https://liberty-carrito.isoft-ste.com';
  // globalip3: string = 'http://34.82.52.101:5013';
  globalip3: string = 'https://movistarcr-social-numa.isoft-ste.com';
  // globalip3: string = 'http://localhost:8081';
  // globalip:string='https://carrito.isoft-ste.com';

  public Data = new Data(
    null,
  );

  constructor(
    public http: HttpClient,
  ) {
    // if(this.Data.version == 'pablo'){
    //   this.globalip='http://3.219.73.19:5049'
    // }else
    // if(this.Data.version != 'tigo' && this.Data.version != ''){
    //   this.globalip='http://3.219.73.19:5050'
    // }
  }

  gentoken() {
    return this.Data.token;
  }

  getEmpresa(params) {
    return this.http.post(this.globalip + '/api/validatetoken', params, {});
    // return this.http.post(this.globalip+'/api/mall/empresa/getlastid', {},{});
  }

  getCategorias(params) {
    return this.http.post(this.globalip + '/api/mall/categoria/getcarrito', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  getSubcategoria(params) {
    return this.http.post(this.globalip + '/api/mall/categoria/getsub', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  getProductos(params) {
    return this.http.post(this.globalip + '/api/mall/producto', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  getImagenesprod(params) {
    return this.http.post(this.globalip + '/api/mall/producto/imagenes', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  getVariantesprod(params) {
    return this.http.post(this.globalip + '/api/mall/variante/producto', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  getPricevariante(params) {
    return this.http.post(this.globalip + '/api/mall/producto/getcantidadprecio', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  savePedido(params) {
    return this.http.post(this.globalip + '/api/mall/pedido', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  getEtiquetas(params) {
    return this.http.post(this.globalip + '/api/mall/etiqueta', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  validateCantprod(params) {
    return this.http.post(this.globalip + '/api/mall/validate_carrito', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  limpiarCarrito(params) {
    return this.http.post(this.globalip + '/api/mall/clean_carrito', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  getMetodosPago(params) {
    return this.http.post(this.globalip + '/api/metodospago/carrito', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  getclienteinfo(params) {
    return this.http.post(this.globalip + '/api/mall/pedido/getclienteinfo', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  getPrecioEnvio(params) {
    return this.http.post(this.globalip + '/api/mall/pedido/getprecioenvio_carrito', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  getDepartamentos(params) {
    return this.http.post(this.globalip + '/api/mall/ubicacion/getdepartamentos', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  getMunicipios(params) {
    return this.http.post(this.globalip + '/api/mall/ubicacion/getmunicipios', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  updateCarrito(params) {
    return this.http.post(this.globalip + '/api/mall/update_pedido_carrito', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  getDetalleSaldo(params) {
    // return this.http.post(this.globalip2 + '/api/isoft/movistarcr/consultasaldotiendaenlinea', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
    return this.http.post(this.globalip3 + '/api/isoft/movistarcr/consultasaldotiendaenlinea', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }
  getBonos(params) {
    // return this.http.post(this.globalip2+'/api/isoft/movistarcr/consumostiendaenlinea', params,{ headers: {'Authorization': 'p '+this.gentoken()}});
    return this.http.post(this.globalip3 + '/api/isoft/movistarcr/consumostiendaenlinea', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  /**
   * **************************************************************************
   * ************************ VILLA NUEVA *************************************
   * **************************************************************************
   */

  getVehiculo(params) {
    return this.http.post(this.globalip + '/ConsultasPMTPort/getVehiculo', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  getVehiculoResumen(params) {
    return this.http.post(this.globalip + '/ConsultasPMTPort/getVehiculoResumen', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  getGenerar_Infracciones_Foto(params) {
    return this.http.post(this.globalip + '/ConsultasPMTPort/getGenerar_Infracciones_Foto', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  getContribuyenteDPI(params) {
    return this.http.post(this.globalip + '/ConsultasPMTPort/getContribuyenteDPI', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  insertLog(params){
    return this.http.post(this.globalip + '/log/insertLog', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  updateLog(params){
    return this.http.post(this.globalip + '/log/updateLog', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  derivarCaso(params){
    return this.http.post(this.globalip + '/api/caso/derivar', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  sendkeywords(params){
    return this.http.post(this.globalip + '/api/caso/sendkeywords', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }

  sendencuesta(params){
    return this.http.post(this.globalip + '/api/caso/sendencuesta', params, { headers: { 'Authorization': 'p ' + this.gentoken() } });
  }
}
