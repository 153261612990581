import { Component, OnInit, Input } from '@angular/core';
import { ModalController, Config } from "@ionic/angular";

@Component({
  selector: 'app-responsesolicitud',
  templateUrl: './responsesolicitud.component.html',
  styleUrls: ['./responsesolicitud.component.scss'],
})
export class ResponsesolicitudComponent implements OnInit {

  ios: boolean;
  @Input() title;
  @Input() tipo;

  constructor(
    public modalCtrl: ModalController,
    public config: Config,
  ) { }

  ngOnInit() {
    this.ios = this.config.get('mode') === `ios`;
    console.log(this.title);
  }

  public close() {
    let response = {
			param:0
		}
    this.modalCtrl.dismiss(response);
  }

}
