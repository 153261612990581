import { Injectable, Inject } from '@angular/core';
import { DomController } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';

interface ThemeStyle{
  themeVariable: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class ThemeswitcherService {

  constructor(
    private domCtrl: DomController,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  setTheme(theme: ThemeStyle[]){
    this.domCtrl.write(() => {
      theme.forEach(style => {
        this.document.documentElement.style.setProperty(style.themeVariable, style.value);
      });
    })
  }
}
