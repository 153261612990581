import { Component, OnInit, Input } from '@angular/core';
import { ModalController, Config } from "@ionic/angular";

@Component({
  selector: 'app-detallebonos',
  templateUrl: './detallebonos.page.html',
  styleUrls: ['./detallebonos.page.scss'],
})
export class DetallebonosPage implements OnInit {

  @Input() bono;
  ios: boolean;
  // bonos = [
  //   {title:'Bono Internet - Plan Libre Prepago',consumido:52,disponible:8,porcentaje:0,vence:'20/03/2021', ilimitado:0},
  //   {title:'Bono Redes Sociales Ilimitadas - Plan Libre Prepago',consumido:47.25,disponible:0,porcentaje:0,vence:'20/03/2021',ilimitado:1},
  //   {title:'Bono Internet - Paquete Conectados',consumido:72.30,disponible:9.93,porcentaje:1,vence:'13/03/2021',ilimitado:0},
  //   {title:'Bono Redes Sociales por Recarga',consumido:25.83,disponible:94.17,porcentaje:26,vence:'24/02/2021',ilimitado:0},
  // ];
  public bonos=[1];

  constructor(
    public modalCtrl: ModalController,
    public config: Config,
  ) { }

  ngOnInit() {
    this.ios = this.config.get('mode') === `ios`;
    // this.bono.bonos.reverse();
    this.bonos = this.bono.bonos;
    // console.log(this.bonos)
  }

  public close() {
    let response = {
			param:0
		}
    this.modalCtrl.dismiss(response);
  }

}
