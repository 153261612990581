import { Component } from '@angular/core';

import { Platform, Config, NavController } from '@ionic/angular';
import { AppService } from 'src/services/app.service';
import { CartService } from 'src/services/cart.service';
import { LocalsaveService } from 'src/services/localsave.service';
import { generateColor } from 'src/utils/parse-css';
import { ThemeswitcherService } from 'src/services/themeswitcher.service';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  ios: boolean;
  name = 'primary';
  value = '#89ba16';
  private empresa: Subscription;
  private rutass: Subscription;
  bandera = 0;
  banderaclean = 0;
  carritonln = [];
  private token: Subscription;
  tmptkn: any;
  tipocliente: any;

  public appPages = [
    { title: 'Información del Vehículo', url: '/home/consultavehiculo', icon: 'paper-plane', id: 1, padre: 1 },
    { title: 'Información de Estado de Cuenta', url: '/home/consultaestadocuenta', icon: 'paper-plane', id: 2, padre: 1 },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  consultavehiculo: string;
  consultaestadocuenta: string;

  constructor(
    private platform: Platform,
    public appservice: AppService,
    public cartservice: CartService,
    public localsave: LocalsaveService,
    private config: Config,
    public themeswitch: ThemeswitcherService,
    private router: Router,
    public navCtrl: NavController,
  ) {
    this.initializeApp();
    this.router.events.subscribe((event: RouterEvent) => {
      if (event && event instanceof NavigationEnd && event.url) {
        // console.log(event.url)
        if (event.url !== '/detalleproducto') {
          let tmp = event.url.split('/home/')[1];
          tmp=tmp.split('/')[0];
          this.consultavehiculo = event.url.replace('/'+tmp, '/consultavehiculo');
          this.consultaestadocuenta = event.url.replace('/'+tmp, '/consultaestadocuenta');
          // this.consultasaldoPath= event.url.replace('/'+tmp, '/consultasaldo');
          // this.paquetesPath = event.url.replace('/'+tmp, '/paquetes');
          // this.prestamosPath = event.url.replace('/'+tmp, '/prestamos');
          // this.recargasPath = event.url.replace('/'+tmp, '/recargas');
          // this.planesprepagoPath = event.url.replace('/'+tmp, '/planesprepago');
          // this.roamingPath = event.url.replace('/'+tmp, '/roaming');
          // this.asistenciaPath = event.url.replace('/'+tmp, '/asistencia');
        }
      }
    });
  }

  ngOnInit() {
    this.token = this.localsave.gettoken().subscribe((res: any) => {
      if (res != 'null') {
        this.tmptkn = res;
      }
    })
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.ios = this.config.get('mode') === `ios`;
      this.rutass = this.router.events.subscribe((event: RouterEvent) => {
        if (event && event instanceof NavigationEnd && event.url) {
          // console.log(event.url.split('/tienda-prepago/').length )
          if (event.url.split('/home/').length == 2) {
            let objectt = event.url.split('/home/')[1];
            objectt = objectt.split('/')[1];
            // console.log(objectt)
            if (objectt != 'undefined') {
              this.localsave.settoken(objectt);
              this.appservice.Data.token = objectt;
              this.localsave.loadcaso(objectt);
              this.empresa = this.localsave.getempresa().subscribe((res:any) => {
                if (res != null && this.bandera==0) {
                  this.tipocliente=this.appservice.Data.tipocliente;
                  if(res.edit == 'error'){
                    this.navCtrl.navigateRoot('/tokeninvalido');
                  }else{
                    this.bandera++;
                    // this.value = res.color;
                    // this.carritonln = JSON.parse(res.carritoonline);
                    // this.changeTheme();
                    this.appservice.Data.saldodisponible = this.localsave.getSaldodisponible().value;
                    // this.localsave.getidcaso().subscribe((res:any) => {
                    //   if(res != null && this.carritonln !== null && this.carritonln !== undefined){
                    //     this.cartservice.cartItemCount.next(this.carritonln.length);
                    //     this.cartservice.setCart(this.carritonln);
                    //   }
                    // })
                  }
                }else{
                  this.bandera=0;
                }
              })
            }
          }
        }
      })

    });
  }

  changeTheme() {
    const name = this.name.toLowerCase();
    const value = this.value;
    const selector = `.ion-color-${name}`;

    const color = generateColor(name, selector, value);

    let objnewPrimary = [
      { themeVariable: `--ion-color-${name}`, value: `${color.value}` },
      { themeVariable: `--ion-color-${name}-rgb`, value: `${color.valueRgb}` },
      { themeVariable: `--ion-color-${name}-contrast`, value: `${color.contrast}` },
      { themeVariable: `--ion-color-${name}-contrast-rgb`, value: `${color.contrastRgb}` },
      { themeVariable: `--ion-color-${name}-shade`, value: `${color.shade}` },
      { themeVariable: `--ion-color-${name}-tint`, value: `${color.tint}` }
    ];

    setTimeout(() => {
      this.themeswitch.setTheme(objnewPrimary);
    }, 1);

  }

  public gotourl(id) {
    // console.log(id);
    if (id == 1) {
      this.navCtrl.navigateRoot(this.consultavehiculo);
    }
    else if (id == 2) {
      this.navCtrl.navigateRoot(this.consultaestadocuenta);
    }
  }

  ngOnDestroy() {
    this.empresa.unsubscribe();
    this.rutass.unsubscribe();
  }
}
